<!-- 菜品信息 -->
<template>
  <div class="page-main">
    <div class="page-info">
      <div class="sidebar-detail">
        <div v-if="tableList.length > 0">
          <van-list v-model:loading="loading" :finished="tableList.length == total" finished-text="没有更多了"
            @load="onLoad">
            <van-cell v-for="(item, index) in tableList" :key="index">
              <div class="dish-item">
                <van-image v-if="index < 3" width="40px" fit="cover" class="ico-recom"
                  :src="require('@/assets/image/ico-recom.png')" />
                <van-card :num="item.meal_num" :price="item.price" :title="item.meal_name"
                  :thumb="require('@/assets/image/dish.png')" class="dish-item-main">
                  <template #title>
                    <div style="font-weight: bold;font-size: 18px;">{{ item.meal_name }}</div>
                  </template>
                  <template #tags>
                    <div class="meal-mode-main">
                      <span>包含餐次：</span>
                      <span v-for="(modeItem, medeIndex) in item.modeList" :key="medeIndex">
                        {{ medeIndex == 0 ? modeItem.mode_name : ("、" + modeItem.mode_name) }}
                      </span>
                    </div>
                  </template>
                  <template #num>
                    <div class="pay-num-opt">
                      <van-button v-if="item.meal_num > 0" plain icon="minus" round type="danger" size="mini"
                        @click="subShoppingCart(item)" />
                      <van-button v-if="item.meal_num > 0" plain hairline type="default" size="mini" disabled><span
                          style="font-size:16px">{{ item.meal_num }}</span></van-button>
                      <van-button icon="plus" round type="primary" size="mini" color="#9F88FF"
                        @click="addShoppingCart(item)" />
                    </div>
                  </template>
                </van-card>
                <div class="nutrition-decs">
                  <div class="decs-nutrition-detail">
                    能量：{{ item.energy }}kcal 蛋白质:{{ item.protein }}g 脂肪：{{ item.fat }}g 碳水化合物：{{ item.carbon }}g
                    纤维素：{{ item.cellulose }}g
                  </div>
                  <!-- <div class="need-know-more" @click="handleKnowMore(item)">
                    了解更多>
                  </div> -->
                </div>
              </div>
            </van-cell>
          </van-list>
        </div>
        <div v-else class="shopping-card-none">
          <div v-if="fetchLoading" class="shopping-card-loading">
            <van-loading size="24px" vertical>加载中...</van-loading>
          </div>
          <div v-else>
            <van-empty image-size="10vh" image="error" description="暂无数据" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <van-dialog v-model:show="moreShow">
    <div class="goods-info-body">
      <div class="goods-info-header">
        营养详情
      </div>
      <div class="goods-info-content">
        <div>
          能量:{{ moreDetail.energy }}kcal
        </div>
        <div>
          蛋白质:{{ moreDetail.protein }}g
        </div>
        <div>
          脂肪:{{ moreDetail.fat }}g
        </div>
        <div>
          碳水化合物:{{ moreDetail.carbon }}g
        </div>
        <div>
          纤维素:{{ moreDetail.cellulose }}g
        </div>
      </div>
    </div>
  </van-dialog>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
// import { getSession } from '@/utils/sessionUtils'
import { getStorage } from '@/utils/localStorageUtils'
import CommonData from '@/utils/commonData'
import canteen from '@/api/ofs/canteen'

import { isDuringDate } from "@/utils/util"
import { Dialog, Toast } from 'vant';

export default {
  name: "chooseMeal",
  components: {},
  emits: ['updShoppingCard'],
  props: {
    orderType: {
      type: Number,
      default: 1
    },
    mealList: {
      type: Array,
      default: null
    }
  },
  setup(props, setupContext) {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();
    onMounted(() => {
      getMealList()
    });
    const onChange = e => {
      console.log("onChange", e)
      data.active = e
      data.page_index = 1
      data.total = 0
      data.loading = false
      data.tableList = []
      getMealList()
    }
    const data = reactive({
      active: 0,
      loading: false,
      total: 0,
      tableList: [],
      page_index: 1,
      page_size: 10,
      moreShow: false,
      moreDetail: {},
      is_add: true,
      fetchLoading: true
    });

    const getMealList = () => {
      const post_data = {
        organ_id: getStorage(CommonData.KeyStorages.KEY_ORGAN_ID),
        cate_id: 0,
        page_index: data.page_index,
        page_size: data.page_size,
        canteen_id: getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID),
      }
      data.fetchLoading = true
      canteen.getMealList(post_data).then(res => {
        //console.log('getMealList', res)
        if (res.data != null && res.data.total > 0) {
          data.total = res.data.total;
          const tableData = res.data.data.map(item => {
            item.meal_num = 0;
            item.price = parseFloat(item.price).toFixed(2);
            if (
              props.mealList != null
              && props.mealList != null
              && props.mealList.length > 0
            ) {
              var findMealInfo = props.mealList.find(o => o.meal_id == item.meal_id);
              if (findMealInfo != null) {
                if (!Object.keys(item).includes('meal_num')) {
                  item['meal_num'] = 0;
                }
                item.meal_num = findMealInfo.meal_num
              }
            }
            return item
          })
          if (data.page_index == 1) {
            data.tableList = tableData
          } else {
            data.tableList = [...data.tableList, ...tableData]
          }
        } else {
          data.tableList = []
        }
        data.loading = false
        data.fetchLoading = false
      }).catch(err => {
        data.fetchLoading = false
      })
    }

    const onLoad = () => {
      console.log("加载更多！")
      data.page_index += 1
      getMealList()
    }

    const handleKnowMore = item => {
      data.moreDetail = item
      data.moreShow = true
    }

    const addShoppingCart = item => {
      //item.meal_num += 1;
      proxy.$emit('updShoppingCard', item, +1)
    }
    const subShoppingCart = item => {
      //item.meal_num -= 1;
      proxy.$emit('updShoppingCard', item, -1)
    }

    return {
      ...toRefs(data),
      handleKnowMore,
      addShoppingCart,
      subShoppingCart,
      onChange,
      onLoad
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.van-button--mini{
  width: 0.64rem;
  border-radius: 50%;
}
.page-main {
  width: 100%;
  margin: 10px 0;

  .page-info {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 80px;

    .sidebar-detail {
      width: 100%;

      :deep(.van-cell:after) {
        border: none;
      }

      .dish-item {
        background: #fafafa;
        position: relative;

        // margin: 10px 0;
        .tags-class {
          margin: 0 5px 3px 0;
        }



        .dish-item-main {
          padding: 0 0 10px 0;
          --van-card-price-color: #ff0000;
        }

        .ico-recom {
          z-index: 1;
          left: 0;
          top: 0;
          position: absolute;
        }

        .tags-class :last-child {
          margin: 0 0 3px 0;
        }

        .meal-mode-main {
          color: #333333;
          font-size: 14px;
          line-height: 18px;
          padding-bottom: 5px;
        }

        .nutrition-decs {
          font-size: 14px;
          line-height: 20px;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;

          .decs-nutrition-detail {
            font-size: 14px !important;
            color: #666666;
            line-height: 18px !important;
          }

          .need-know-more {
            color: #9F88FF;
          }
        }

        .pay-num-opt {
          align-items: center;
          justify-content: center;
        }
      }

      .shopping-card-none {
        height: 100%;
        font-size: 18px;
        color: #AAAAAA;

        .shopping-card-loading {
          margin-top: 10vh;
        }
      }
    }

    .van-sidebar-item--select::before {
      background-color: #9F88FF;
    }
  }

  .goods-info-body {
    max-height: 50%;
    margin: 20px;

    .goods-info-header {
      text-align: center;
      font-size: 16px;
      line-height: 35px;
      font-weight: bold;
    }

    .goods-info-content {
      font-size: 14px;
      color: #666666;
      line-height: 22px;
    }
  }
}
</style>
