<!-- 套餐订餐页面 -->
<template>
  <div class="page-main">
    <div v-if="sucCartLoading == false" class="cart-load">
      <van-loading type="spinner" color="#FFFFFF" />
      <div style="margin-top: 10px">加载中...</div>
    </div>
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
      <div class="header-content">{{ type == 1 ? "今日点餐" : "明日点餐" }}</div>
    </header>
    <section class="page-info">
      <div class="meal-detail">
        <div class="meal-type-tabs" v-if="sucCartLoading">
          <ChooseMeal :cart-obj="cartObj" :order-type="type" :meal-list="shoppingCardList"
            @upd-shopping-card="updShoppingCard" />
        </div>
        <div class="summit">
          <van-action-bar>
            <van-action-bar-icon icon="cart-o" text="购物车" :badge="shoppingGoodsTotal" @click="onClickShoppingCard" />
            <van-action-bar-icon v-if="shoppingTotalPrice > 0">
              <template #default>
                <div class="total-price">￥{{ shoppingTotalPrice }}</div>
              </template>
            </van-action-bar-icon>
            <van-action-bar-button color="#be99ff" type="warning" text="营养分析" @click="onNutritionShow" />
            <van-action-bar-button color="#7232dd" type="danger" text="去下单" @click="onSubmit" />
          </van-action-bar>
        </div>
      </div>
    </section>
  </div>
  <van-dialog v-model:show="moreShow">
    <div class="goods-info-body">
      <div class="goods-info-header">营养分析</div>
      <div v-if="analysisLoading" class="goods-info-content">
        <div>能量:{{ moreDetail.energy }}kcal</div>
        <div>蛋白质:{{ moreDetail.protein }}g</div>
        <div>脂肪:{{ moreDetail.fat }}g</div>
        <div>碳水化合物:{{ moreDetail.carbon }}g</div>
        <div>纤维素:{{ moreDetail.cellulose }}g</div>
      </div>
      <div v-else class="goods-info-content analysis">
        <van-loading type="spinner" vertical> 分析中... </van-loading>
      </div>
    </div>
  </van-dialog>
  <van-action-sheet v-model:show="shoppingCardShow" title="购物车" z-index="33" style="padding-bottom: 60px">
    <div class="shopping-card-content">
      <div v-if="shoppingCardList.length > 0">
        <div v-for="(item, dIndex) in shoppingCardList" :key="dIndex">
          <van-card :num="item.meal_num" :price="item.price" :title="item.meal_name"
            :thumb="require('@/assets/image/dish.png')" class="dish-card">
            <template #title>
              <div style="font-weight: bold">{{ item.meal_name }}</div>
            </template>
            <template #tags>
              <div v-for="(modeItem, medeIndex) in item.mode_list" style="line-height: 20px" :key="medeIndex">
                <div>
                  <div style="color: #42a5f5;">{{ modeItem.mode_name }}</div>
                  <div style="font-size: 13px; color: #666666">
                    <span v-for="(dishesItem, dishes_index) in modeItem.dishes_list" :key="dishes_index">
                      {{ dishes_index == 0 ? dishesItem.dishes_name : '、' + dishesItem.dishes_name }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template #num>
              <div class="pay-num-opt">
                <van-button v-if="item.meal_num > 0" plain icon="minus" round type="danger" size="mini"
                  @click="subShoppingCart(item)" />
                <van-button v-if="item.meal_num > 0" plain hairline type="default" size="mini" disabled><span
                    style="font-size: 16px">{{ item.meal_num }}</span></van-button>
                <van-button icon="plus" round type="danger" size="mini" color="#9F88FF"
                  @click="addShoppingCart(item)" />
              </div>
            </template>
          </van-card>
        </div>
      </div>
      <div v-else class="shopping-card-none">
        <van-empty image-size="10vh" image="error" description="暂无数据" />
      </div>
    </div>
  </van-action-sheet>

  <div class="choose-dishes">
    <van-dialog v-model:show="choose.show" :confirm-button-text="choose.btnTxt" :before-close="chooseBeforeClose"
      confirm-button-color="#ee0a24" title="规格选择" show-cancel-button @cancel="choose.show = false">
      <div class="meal-main" :style="'height:' + choose.height + 'px;overflow-y: auto;padding: 15px;'">
        <div class="base">
          <div class="img-main">
            <van-image class="img" fit="fill" width="70px" height="70px" :src="require('@/assets/image/dish.png')" />
          </div>
          <div class="con">
            <div class="name">{{ choose.mealObj.meal_name }}</div>
            <div class="mode-desc">餐次：{{ choose.mealObj.mode_name_str }}</div>
            <div class="amt-main">
              <span class="tit">价格</span>
              <span class="amt"><span style="font-size: 14px">￥</span>{{ choose.mealObj.price }}</span>
            </div>
          </div>
        </div>
        <div class="mode-main">
          <div v-for="(modeInfo, modeIndex) in choose.mealObj.modeList" :key="modeIndex" class="mode-item">
            <div class="mode-name">{{ modeInfo.mode_name }}</div>
            <section v-for="(groupInfo, groupIndex) in choose.mealObj.mealGroupList" :key="groupIndex">
              <div class="group" v-if="modeInfo.meal_mode == groupInfo.meal_mode">
                <div class="group-item">
                  <div class="group-name">
                    <span class="name">{{ groupInfo.group_name_str }}</span>
                    <span v-if="groupInfo.group_type == 2" class="shooseed">（已选{{ groupInfo.chooseNum }}份）</span>
                  </div>
                  <div v-if="groupInfo.group_type == 1" class="dishes-main">
                    <div v-for="(dishesInfo, dishesIndex) in groupInfo.dishesList" :key="dishesIndex" class="dishes-1">
                      {{ dishesInfo.dishes_name }}
                    </div>
                  </div>
                  <div v-else class="dishes-main">
                    <div v-for="(dishesInfo, dishesIndex) in groupInfo.dishesList" :key="dishesIndex"
                      :class="'dishes-2 ' + (dishesInfo.active == 1 ? 'active' : '')"
                      @click="chooseDishesClick(dishesInfo)">
                      {{ dishesInfo.dishes_name }}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs, watch, Dialog } from "vue";
import { useRouter } from "vue-router";
import shopCart from "@/api/ofs/shopCart";
import { isEmptyObject, isEmpty } from "@/utils/util";
import { getStorage } from "@/utils/localStorageUtils";
import { Toast } from "vant";
import ChooseMeal from "./component/choose.meal.vue";
import CommonData from "@/utils/commonData";

export default {
  name: "makeMealOrder",
  components: {
    ChooseMeal,
  },
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options);
      if (options != null && options.type != null) {
        data.type = options.type;
      }
      getShopCart();
      data.choose.height = (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) * 0.8;
    });


    const data = reactive({
      show: false,
      type: 1, // 1 今日订餐 2 明日订餐
      shoppingCardShow: false,
      shoppingGoodsTotal: 0,
      shoppingCardList: [],
      shoppingCardObj: {},
      // 营养分析
      moreShow: false,
      moreDetail: {},
      analysisLoading: false,
      // 购物车支付金额
      shoppingTotalPrice: 0.0,
      noEditShopCart: true,
      sucCartLoading: false,

      cartObj: {},
      choose: {
        subNum: 0,
        show: false,
        btnTxt: "确定",
        height: 0,
        mealObj: {},
      },
    });

    // 点餐类型 1、 食堂菜品点餐 2、 预制菜定个餐 3、膳食套餐
    const shop_type = ref(3);

    const chooseBeforeClose = (action) => {
      if (action === "confirm") {
        //先清空购物车
        data.shoppingCardList = [];
        data.choose.subNum++;
        console.log('shoppingCardList', data.shoppingCardList)
        console.log('chooseBeforeClose', data.choose.mealObj)
        //验证是否选择菜品完毕
        var err = '';
        var errobj = {};
        for (var i = 0; i < data.choose.mealObj.mealGroupList.length; i++) {
          var groupInfo = data.choose.mealObj.mealGroupList[i];
          if (parseInt(groupInfo.choose_val) != parseInt(groupInfo.chooseNum) && parseInt(groupInfo.group_type) != 1) {
            if (!Object.keys(errobj).includes("mode_" + groupInfo.meal_mode)) {
              var mode_name = data.choose.mealObj.mealNameObj['mode_' + groupInfo.meal_mode];
              err += isEmpty(err) ? mode_name : "、" + mode_name;
              errobj["mode_" + groupInfo.meal_mode] = mode_name;
            }
          }
        }
        if (!isEmpty(err)) {
          Toast('请正确选择' + err + '菜品');
          return false;
        }
        data.sucCartLoading = false;
        addChooseCart();
        return true;
      }
    };

    /**
     * 将选择的商品加入购物车
     */
    const addChooseCart = () => {
      var chooseInfo = data.choose.mealObj;
      //console.log('shoppingCardObj', data.shoppingCardObj);
      //console.log('chooseInfo', chooseInfo);
      //设置菜品数据与购物车格式一致
      var cartItem = chooseInfo;
      var modeObj = {};
      var dishesIds = {};
      for (var i = 0; i < chooseInfo.mealGroupList.length; i++) {
        var groupInfo = chooseInfo.mealGroupList[i];
        var modeKey = "mode_" + groupInfo.meal_mode;
        if (!Object.keys(modeObj).includes(modeKey)) {
          modeObj[modeKey] = { meal_mode: groupInfo.meal_mode, mode_name: chooseInfo.mealNameObj['mode_' + groupInfo.meal_mode], dishes_list: [] };
        }
        if (!Object.keys(dishesIds).includes(modeKey)) {
          dishesIds[modeKey] = [];
        }
        for (var j = 0; j < groupInfo.dishesList.length; j++) {
          var dishesInfo = groupInfo.dishesList[j];
          if (groupInfo.group_type == 1 || dishesInfo.active == 1) {
            modeObj[modeKey].dishes_list.push(dishesInfo);
            dishesIds[modeKey].push(dishesInfo.dishes_id);
          }
        }
      }
      cartItem['mode_list'] = Object.keys(modeObj).map((key) => modeObj[key]);
      //console.log('cartItem', cartItem);
      //console.log('dishesIds', dishesIds);
      //验证当前选择套餐在购物车是否存在
      if (!Object.keys(data.shoppingCardObj).includes("meal_" + chooseInfo.meal_id)) {
        //购物车不存在此此菜品，直接加入
        cartItem.meal_num = 1;
        data.shoppingCardList.push(cartItem);
      } else {
        //存在时，验证商品数据是否一致
        var cartInfo = data.shoppingCardObj["meal_" + chooseInfo.meal_id];
        var cartDishesids = {};
        for (var k = 0; k < cartInfo.mode_list.length; k++) {
          var cartModeInfo = cartInfo.mode_list[k];
          var cartModeKey = "mode_" + cartModeInfo.meal_mode;
          if (!Object.keys(cartDishesids).includes(cartModeKey)) {
            cartDishesids[cartModeKey] = [];
          }
          for (var h = 0; h < cartModeInfo.dishes_list.length; h++) {
            var cartDishesInfo = cartModeInfo.dishes_list[h];
            cartDishesids[cartModeKey].push(cartDishesInfo.dishes_id);
          }
        }
        console.log('cartDishesids', cartDishesids);
      }
    };

    const updShoppingCard = (e, num) => {
      console.log('updShoppingCard', e, num)
      data.shoppingCardList = [];
      editShoppingCard();
      if (!Object.keys(e).includes('meal_num') || parseInt(e.meal_num) == 0) {
        var dishesObj = {};
        var mealNameObj = {};
        e.mode_name_str = '';
        for (var j = 0; j < e.modeList.length; j++) {
          var modeInfo = e.modeList[j];
          if (!Object.keys(mealNameObj).includes('mode_' + modeInfo.meal_mode)) {
            mealNameObj['mode_' + modeInfo.meal_mode] = modeInfo.mode_name;
          }
          e.mode_name_str += j > 0 ? "、" + modeInfo.mode_name : modeInfo.mode_name;
          for (var k = 0; k < modeInfo.dishes_list.length; k++) {
            var dishesInfo = modeInfo.dishes_list[k];
            if (!Object.keys(dishesObj).includes('group_' + dishesInfo.group_id)) {
              dishesObj['group_' + dishesInfo.group_id] = [];
            }
            dishesInfo.active = 0;
            modeInfo.dishes_list[k] = dishesInfo;
            dishesObj['group_' + dishesInfo.group_id].push(dishesInfo);
          }
          e.modeList[j] = modeInfo;
        }

        e.isChoose = 0;
        for (var i = 0; i < e.mealGroupList.length; i++) {
          var groupInfo = e.mealGroupList[i];
          groupInfo.chooseNum = 0;
          if (groupInfo.group_type != 1) {
            e.isChoose = 1;
          }

          groupInfo.dishesList = dishesObj['group_' + groupInfo.group_id];
          groupInfo.dishesLen = groupInfo.dishesList.length;
          if (groupInfo.group_type == 1) {
            groupInfo.choose_val = groupInfo.dishesLen;
            groupInfo.group_name_str = isEmpty(groupInfo.group_name) ? '已包含' : groupInfo.group_name + "【已包含】";
          } else {
            groupInfo.choose_val = parseInt(groupInfo.choose_val) > 0 ? groupInfo.choose_val : 1;
            groupInfo.group_name_str = (isEmpty(groupInfo.group_name) ? '以下菜品' : groupInfo.group_name) + "【" + groupInfo.dishesLen + "选" + groupInfo.choose_val + "】";
          }

          e.mealGroupList[i] = groupInfo;
        }

        e.mealNameObj = mealNameObj;
        // if (e.isChoose == 1) {
        //   data.choose.show = true;
        // }
        data.choose.show = true;
        data.choose.mealObj = e;
      } else {
        e.meal_num += num;
      }
    };

    /**
     * 设置可选菜品的选择状态
     */
    const chooseDishesClick = item => {
      //console.log('chooseDishesClick', item);
      for (var i = 0; i < data.choose.mealObj.mealGroupList.length; i++) {
        var groupInfo = data.choose.mealObj.mealGroupList[i];
        if (item.group_id != groupInfo.group_id || groupInfo.meal_mode != item.meal_mode) {
          continue;
        }
        if(groupInfo.choose_val == 1){
          groupInfo.chooseNum = 0;
        }
        for (var j = 0; j < groupInfo.dishesList.length; j++) {
          var dishesInfo = groupInfo.dishesList[j];
          if (!Object.keys(dishesInfo).includes('active') || groupInfo.choose_val == 1) {
            dishesInfo.active = 0;
          }
          if (dishesInfo.dishes_id == item.dishes_id) {
            if (dishesInfo.active == 1) {
              dishesInfo.active = 0;
              groupInfo.chooseNum -= 1;
            } else {
              if (groupInfo.chooseNum < groupInfo.choose_val) {
                dishesInfo.active = 1;
                groupInfo.chooseNum += 1;
              }
            }
          }
          // if (parseInt(groupInfo.choose_val) == 1) {
          //   dishesInfo.active = 0;
          //   groupInfo.chooseNum = 0;
          // }
          groupInfo.chooseNum = groupInfo.chooseNum < 0 ? 0 : groupInfo.chooseNum;
          groupInfo.dishesList[j] = dishesInfo;
        }
        data.choose.mealObj.mealGroupList[i] = groupInfo;
      }
    }

    // 监听数字变化
    watch(
      () => data.shoppingCardList,
      (newVal) => {
        var totalPrice = 0.0;
        if (newVal != null && newVal.length > 0) {
          newVal.map((meal_item) => {
            if (meal_item != null) {
              totalPrice += meal_item.meal_num * parseFloat(meal_item.price);
            }
          });
        }
        data.shoppingTotalPrice = totalPrice.toFixed(2);
        data.shoppingGoodsTotal = data.shoppingCardList.length;
        if (data.noEditShopCart) {
          data.noEditShopCart = false;
          return;
        }
        editShoppingCard();
      },
      { deep: true }
    );

    const getShopCart = () => {
      data.sucCartLoading = false;
      var shopCartList = [];
      const post_data = {
        shop_type: shop_type.value,
        order_type: data.type,
        canteen_id: getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID),
      };
      shopCart
        .getShopCartList(post_data)
        .then((res) => {
          console.log(res)
          var shoppingCardObj = {};
          if (res.data != null && res.data.length > 0) {
            if (data.type == 1) {
              data.noEditShopCart = false;
            }
            res.data.map((item) => {
              data.cartObj["meal_" + item.meal_id] = item;
              item.price = parseFloat(item.price).toFixed(2);
              item.pay_price = (item.meal_num * parseFloat(item.price)).toFixed(2);
              item.dishes_id = item.meal_id;
              item.dishes_num = item.dishes_num;
              shopCartList.push(item);
              if (!Object.keys(shoppingCardObj).includes("meal_" + item.meal_id)) {
                shoppingCardObj["meal_" + item.meal_id] = item;
              }
            });
          }
          //data.shoppingCardObj = shoppingCardObj;
          //data.shoppingCardList = shopCartList;
          data.sucCartLoading = true;
        })
        .catch((err) => {
          data.sucCartLoading = true;
        });
    };

    const handleShowAttention = () => {
      data.show = true;
    };

    const onNutritionShow = () => {
      if (data.shoppingGoodsTotal <= 0) {
        Toast("购物车无数据！");
        return;
      }
      data.analysisLoading = false;
      data.moreShow = true;
      var energy = 0.0;
      var fat = 0.0;
      var protein = 0.0;
      var carbon = 0.0;
      var cellulose = 0.0;
      // 循环购物车计算
      data.shoppingCardList.map((item) => {
        energy += item.energy;
        fat += item.fat;
        protein += item.protein;
        carbon += item.carbon;
        cellulose += item.cellulose;
      });
      data.moreDetail = {
        energy: energy.toFixed(2),
        fat: fat.toFixed(2),
        protein: protein.toFixed(2),
        carbon: carbon.toFixed(2),
        cellulose: cellulose.toFixed(2),
      };
      data.analysisLoading = true;
    };
    const onSubmit = () => {
      console.log("提交订单!");
      if (data.shoppingGoodsTotal <= 0) {
        Toast("购物车无数据，无法下单！");
        return;
      }
      Toast.loading({
        message: "下单中...",
        forbidClick: true,
        duration: 0,
      });
      setTimeout(() => {
        Toast.clear();
        const params = {
          order_type: data.type,
          shop_type: shop_type.value,
        };
        $router.push({
          query: params,
          name: "doMealOrder",
        });
      }, 1000);
    };

    const addShoppingCart = (item) => {
      console.log(item);
      item.meal_num += 1;
      item.pay_price = (item.meal_num * parseFloat(item.price)).toFixed(2);
      item.dishes_id = item.meal_id;
      item.dishes_num = item.meal_num;
    };
    const subShoppingCart = (item) => {
      item.meal_num -= 1;
      item.pay_price = (item.meal_num * parseFloat(item.price)).toFixed(2);
      item.dishes_id = item.meal_id;
      item.dishes_num = item.meal_num;
      // 如果减到0了  直接从购物车删除
      if (item.meal_num == 0) {
        var index_config = data.shoppingCardList
          .map((e) => {
            return e.meal_id;
          })
          .indexOf(item.meal_id);

        if (index_config >= 0) {
          data.shoppingCardList.splice(index_config, 1);
        }
      }
    };

    const onClickShoppingCard = () => {
      data.shoppingCardShow = true;
    };
    const editShoppingCard = () => {
      //data.sucCartLoading = false;
      var meal_list = [];
      data.shoppingCardList.map((item) => {
        console.log('editShoppingCard', item)
        var meal_info = {
          meal_id: item.meal_id,
          meal_num: item.meal_num,
          dishes_id: item.meal_id,
          dishes_num: item.meal_num,
          dishesList: [],
        };
        for (var i = 0; i < item.mode_list.length; i++) {
          var modeInfo = item.mode_list[i];
          for (var j = 0; j < modeInfo.dishes_list.length; j++) {
            var dishesInfo = modeInfo.dishes_list[j];
            meal_info.dishesList.push({
              cart_id: 0,
              dishes_id: dishesInfo.dishes_id,
              dishes_num: item.meal_num,
              meal_mode: modeInfo.meal_mode,
              mode_name: modeInfo.mode_name,
              dishes_name: dishesInfo.dishes_name,
            });
          }
        }
        meal_list.push(meal_info);
      });
      var post_data = {
        shop_type: shop_type.value,
        order_type: data.type,
        dishes_list: JSON.stringify(meal_list),
        canteen_id: getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID),
      };

      shopCart.editShopCart(post_data).then((res) => {
        //data.sucCartLoading = true;
        if (res.code == 0 && meal_list.length > 0) {
          onSubmit();
        }
      }).catch((err) => {
        //data.sucCartLoading = true;
      });
    };

    return {
      ...toRefs(data),
      handleShowAttention,
      onNutritionShow,
      onSubmit,
      onClickShoppingCard,
      updShoppingCard,
      addShoppingCart,
      subShoppingCart,
      chooseBeforeClose,
      chooseDishesClick,
    };
  },
  methods: {},
};
</script>

<style>
.choose-dishes {
  .meal-main {
    .base {
      display: flex;

      .img-main {
        width: 70px;
        .img{
          width: 70px;
          height: 70px;
        }
      }

      .con {
        flex: 1;
        padding-left: 10px;

        .name {
          font-size: 16px;
          line-height: 18px;
        }

        .mode-desc {
          margin-top: 7px;
          font-size: 14px;
          line-height: 20px;
          color: #666666;
          line-height: 15px;
        }

        .amt-main {
          line-height: 17px;
          margin-top: 7px;
          text-align: left;

          .tit {
            font-size: 14px;
            color: #333333;
          }

          .amt {
            font-size: 16px;
            color: #ff0000;
            font-weight: bold;
            margin-left: 5px;
          }
        }
      }
    }

    .mode-main {
      margin-top: 10px;

      .mode-item {
        border-radius: 5px;
        margin-bottom: 10px;

        .mode-name {
          color: #333333;
          background: #e5e5e5;
          padding: 7px 10px;
          font-weight: bold;
        }

        .group {
          .group-item {
            padding-top: 5px;
            .group-name {
              margin-top: 10px;
              border-left: 3px solid #fdce0a;
              height: 16px;
              line-height: 16px;
              padding-left: 8px;

              .name {
                font-size: 14px;
                color: #333333;
                font-weight: bold;
              }

              .shooseed {
                color: #ff0000;
                font-size: 14px;
              }

            }

            .dishes-main {
              margin-top: 15px;
              padding-left: 10px;
              padding-bottom: 5px;
              font-size: 15px;

              .dishes-2 {
                padding: 5px 10px;
                margin-bottom: 10px;
                margin-right: 10px;
                display: inline-block;
                border: 1px solid #dddddd;
                border-radius: 7px;
              }

              .active {
                border: 1px solid #f4b403;
                color: #f4b403;
              }

              .dishes-1 {
                line-height: 25px;
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }

  .van-dialog {
    top: 50%;

    .van-dialog__header {
      padding-top: 15px;
    }
  }
}
</style>

<style scoped lang="scss">
.page-main {
  width: 100%;

  .cart-load {
    position: fixed;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    padding-top: 300px;
    z-index: 100000;
    background: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
  }

  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;

    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }

    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }

  .page-info {
    height: 100%;

    .order-attention {
      margin: 20px 0;
      color: #aaaaaa;
      font-size: 14px;
      line-height: 24px;

      .notice-style {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }

    .summit {
      z-index: 100;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;

      .total-price {
        font-size: 16px;
        color: #ee0a24;
      }
    }
  }
}

.goods-info-body {
  max-height: 50%;
  min-height: 150px;
  height: 100%;
  margin: 20px;

  .goods-info-header {
    text-align: center;
    font-size: 16px;
    line-height: 35px;
    font-weight: bold;
  }

  .goods-info-content {
    font-size: 14px;
    color: #666666;
    line-height: 22px;
  }

  .analysis {
    height: 150px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
}

.attention-show-content {
  min-height: 66vh;

  .show-info {
    margin: 0 10px;

    .show-info-header {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      color: #9932cc;
      font-size: 20px;
      line-height: 30px;
    }

    .time-table {
      margin: 10px 0;
      font-size: 14px;
    }

    .attention-notice {
      margin: 10px 0;
      font-size: 14px;
    }
  }
}

.shopping-card-content {
  padding: 16px;
  min-height: 33vh;
  max-height: 53vh;

  .tags-class {
    margin: 0 5px 3px 0;
  }

  .dish-card {
    --van-card-price-color: #ff0000;
  }

  .tags-class :last-child {
    margin: 0 0 3px 0;
  }

  .shopping-card-none {
    height: 100%;
    font-size: 18px;
    color: #aaaaaa;
  }

  .meal-mode-item {
    margin: 0;
    padding-bottom: 20px;

    .meal-mode-str {
      font-size: 16px;
      font-weight: bold;
      padding: 0 0 10px 0;
    }
  }
}
</style>
